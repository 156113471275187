import React from 'react';
import {request, request_file} from './request.js';
import Descargas from './Descargas.js';
import PieDePagina2 from './Footer2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../images/logo1.png';
import './style.css';
import './animate.min.css';
import Swal from 'sweetalert2';

class Solicitud extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state={
			usuario :
			{
				id : '',
				check3 : 0,
				check7 : 0,
			},
			solicitud :
			{
				id : '',
				num_control : '',
				semestre : '',
				grupo : '',
				turno : '',
				especialidad : '',
				apellido_paterno : '',
				apellido_materno : '',
				nombre : '',
				curp : '',
				fecha_de_inscripcion : '',
				fecha_de_nacimiento : '',
				sexo : '',
				nacionalidad : '',
				entidaddenacimiento : '',
				municipiodenacimiento : '',
				calle : '',
				numero : '',
				colonia : '',
				cp : '',
				telefono : '',
				celular : '',
				correo : '',
				nombrepadre1 : '',
				sexopadre1 : '',
				telefonopadre1 : '',
				domiciliopadre1 : '',
				trabajopadre1 : '',
				telefonotrabajopadre1 : '',
				nombrepadre2 : '',
				sexopadre2 : '',
				telefonopadre2 : '',
				domiciliopadre2 : '',
				trabajopadre2 : '',
				telefonotrabajopadre2 : '',
				contactoemergencia : '',
				telefonoemergencia : '',
				parentescoemergencia : '',
				imprimir : false,
			},
			salir : false,
		}
	}

	componentDidMount()
	{
		var { usuario, solicitud } = this.state;
		let temp = this;

		usuario.id = this.props.id;

		request.get('/buscarSolicitud/'+usuario.id)
		.then(function(response)
		{
			if(response.status === 200)
			{
				solicitud = response.data;
				temp.setState({ usuario : usuario, solicitud : solicitud });
			}
		})
		.catch(error => { });
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var {solicitud} = this.state;

		solicitud[name] = value;

		this.setState({ solicitud : solicitud });
	}

	imprimir=()=>
	{
		let { solicitud } = this.state;

		request_file()
		.post(`/solicitud?id=${solicitud.id}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var { solicitud, imprimir, usuario } = this.state;
		let temp = this;

		var error=1, mensaje="";


		if(solicitud.entidaddenacimiento.length>=30)
		{
			mensaje="El nombre del pais de nacimiento es muy largo";
		}
		else if(solicitud.municipiodenacimiento.length>=30)
		{
			mensaje="El nombre del municipio de nacimiento es muy largo";
		}
		else if(solicitud.calle.length>=50)
		{
			mensaje="El nombre de la calle es muy largo";
		}		
		else if(solicitud.numero.length>=25)
		{
			mensaje="El numero de la casa es muy largo";
		}
		else if(solicitud.colonia.length>=50)
		{
			mensaje="El nombre de la colonia es muy largo";
		}
		else if((solicitud.cp.length<5) || (solicitud.cp.length>5))
		{
			mensaje="El codigo postal no es válido";
		}
		// else if(solicitud.nss.length!=11)
		// {
		// 	mensaje="El número de seguro social no es válido";
		// }
		else if(solicitud.curp.length!=18)
		{
			mensaje="El CURP no es válido";
		}
		else if(solicitud.nombrepadre1.length>=60)
		{
			mensaje="El nombre del primer padre es muy largo";
		}
		else if(solicitud.telefonopadre1.length>=18)
		{
			mensaje="El teléfono del primer padre es muy largo";
		}
		else if(solicitud.domiciliopadre1.length>=60)
		{
			mensaje="La dirección del primer padre es muy larga";
		}
		else if(solicitud.trabajopadre1.length>=40)
		{
			mensaje="El nombre del trabajo del primer padre es muy largo";
		}
		else if(solicitud.telefonotrabajopadre1.length>=18)
		{
			mensaje="El teléfono del trabajo del primer padre es muy largo";
		}
		else if(solicitud.nombrepadre2.length>=60)
		{
			mensaje="El nombre del segundo padre es muy largo";
		}
		else if(solicitud.telefonopadre2.length>=18)
		{
			mensaje="El teléfono del segundo padre es muy largo";
		}
		else if(solicitud.domiciliopadre2.length>=60)
		{
			mensaje="La dirección del segundo padre es muy larga";
		}
		else if(solicitud.trabajopadre2.length>=40)
		{
			mensaje="El nombre del trabajo del segundo padre es muy largo";
		}
		else if(solicitud.telefonotrabajopadre2.length>=18)
		{
			mensaje="El teléfono del trabajo del segundo padre es muy largo";
		}
		else if(solicitud.contactoemergencia.length>=25)
		{
			mensaje="El nombre del contacto de emergencia es muy largo";
		}
		else if(solicitud.telefonoemergencia.length>=18)
		{
			mensaje="El teléfono del contacto de emergencia es muy largo";
		}
		else if(solicitud.parentescoemergencia.length>=25)
		{
			mensaje="El parentesco del contacto de emergencia es muy largo";
		}
		else
		{
			error=0;
		}

		if(error === 0)
		{
			request.post('/GuardarSolicitud',solicitud)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(parseInt(response.data.id,10) > 0)
					{
						Swal.fire({
							position: 'top-end',
							type: 'success',
							title: 'Los datos fueron guardados',
							showConfirmButton: false,
							timer: 1500
						});
						solicitud = response.data;
						usuario.check3 = 1;
						temp.setState({solicitud : solicitud});
					}
					else
					{
						Swal.fire({
							position: 'top-end',
							type: 'error',
							title: 'No se guardo la información.',
							showConfirmButton: false,
							timer: 1500
						});
					}
				}
			})
			.catch(error =>
			{
				Swal.fire({
					position: 'top-end',
					type: 'error',
					title: 'No se guardo la información.',
					showConfirmButton: false,
					timer: 1500
				});
			});
			this.setState({solicitud : solicitud});
		}
		else
		{
			Swal.fire({
						position: 'top-end',
						type: 'error',
						title: mensaje,
						showConfirmButton: false,
						timer: 2500
					});
		}
	}

	salir=()=>
	{
		var {salir} = this.state;
		salir=true;
		this.setState({salir : salir});
	}

	render()
	{
		let { salir, imprimir, solicitud, usuario } = this.state;

		if(salir) return <Descargas {...usuario} />;

		return (
			<div className="principal">

				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					&nbsp;
					<span
						className="nombre oi navbar-brand bl h-100 align-middle" data-glyph="person" aria-hidden="true">
						<i > &nbsp;{(solicitud.nombre)} {solicitud.apellido_paterno} {solicitud.apellido_materno} </i>
					</span>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" style={{ position: 'absolute', right: '40px' }} id="navbarNav">
						<div className="navbar-nav ">
							<button className="nav-item nav-link btn salir" onClick={this.salir}>
								SALIR&ensp;<span className="oi" data-glyph="account-logout" aria-hidden="true"> </span>
							</button>
						</div>
					</div>
				</nav>

				{/* CAMPOS PARA CAPTURA */}
				<div className="inicio container-fluid">
					<div className="row espacio2"> </div>
					<div className="row pb-4">
						<div className="col-12">
							<div className="row h-100 justify-content-around">
								<div className="col-12 col-sm-12 col-md-10 col-lg-10 align-self-center ">
									<p className="kau bl lg"> SOLICITUD DE REINSCRIPCIÓN</p>
									<p className="lat gr3 sm"> DEBERÁS LLENAR ESTA SOLICITUD CON TUS DATOS. </p>
									<p className="lat gr3 sm"> UNA VEZ GUARDADA PUEDES REGRESAR Y CORREGIRLA E IMPRIMIRLA NUEVAMENTE CUANDO QUIERAS. </p>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center pb-5">
						<div className="col-12 col-sm-12 col-md-11">
							<form onSubmit={this.handleFormSubmit}>
								<div className="card text-center w-100">
									<div className="card-header der"> 
										{
											parseInt(solicitud.imprimir,10) === 1?
												<button type="button" className="btn btn-warning" onClick={this.imprimir}>
													<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button> 
											:
												<button type="button" className="btn btn-warning" disabled>
													<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
												</button>
										}
	 								</div>
									<div className="card-body container-fluid">
										<div className="row pt-2 pb-4">
											<h4 className="col-12 cen gr md"> DATOS GENERALES </h4>
										</div>
										<div className="row">
											<div className="col-9 col-sm-7 col-md-6 col-lg-5 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="No. Control">Num. de Control</span>
													</div>
													<input type="text" className="form-control" name="num_control" value={solicitud.num_control} aria-label="Num_Control" aria-describedby="num_control" disabled/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="semestre">Semestre</span>
													</div>
													<input type="text" className="form-control" name="semestre" value={solicitud.semestre} aria-label="Semestre" aria-describedby="semestre" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="grupo">Grupo</span>
													</div>
													<input type="text" className="form-control" name="grupo" value={solicitud.grupo} placeholder="" aria-label="Grupo" aria-describedby="grupo" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-6 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" htmlFor="turno">Turno</span>
													</div>
													<select className="custom-select" name="turno" value={solicitud.turno} id="turno" disabled onChange={this.handleInputChange}>
														<option value=""></option>
														<option value="M">MATUTINO</option>
														<option value="V">VESPERTINO</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" htmlFor="especialidad">Especialidad</span>
													</div>
													<select className="custom-select" name="especialidad" value={solicitud.especialidad} id="especialidad" disabled onChange={this.handleInputChange}>
														<option value=""></option>
														<option value="A">PRODUCCIÓN INDUSTRIAL DE ALIMENTOS</option>
														<option value="O">OFIMÁTICA</option>
														<option value="S">CIBERSEGURIDAD</option>
														<option value="P">PROGRAMACIÓN</option>
														<option value="C">CONTABILIDAD</option>
														<option value="E">ELECTRÓNICA</option>
														<option value="L">LABORATORISTA CLINICO</option>
														<option value="M">MANTENIMIENTO AUTOMOTRIZ</option>
													</select>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="periodo">Periodo escolar:</span>
													</div>
													<input type="text" className="form-control" value="Febrero - Julio 2025 (2024-2)" aria-label="Periodo" aria-describedby="periodo" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="fecha_de_inscripcion">Fecha de reinscripción</span>
													</div>
													<input type="text" className="form-control" name="fecha_de_inscripcion" value={solicitud.fecha_de_inscripcion} aria-label="Fecha_de_inscripcion" aria-describedby="fechadeinscripcion" disabled/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DEL ALUMNO </h4>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombre">Nombre</span>
													</div>
													<input type="text" className="form-control cap negra" value={solicitud.nombre} aria-label="Nombre" aria-describedby="nombre" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="apellido_paterno">Apellido paterno</span>
													</div>
													<input type="text" className="form-control cap negra" value={solicitud.apellido_paterno} aria-label="Apellido_paterno" aria-describedby="apellido_paterno" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="apellido_materno">Apellido materno</span>
													</div>
													<input type="text" className="form-control cap" value={solicitud.apellido_materno} aria-label="Apellido_materno" aria-describedby="apellido_materno" disabled/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-7 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="fecha_de_nacimiento">Fecha de nacimiento</span>
													</div>
													<input type="date" className="form-control" name="fecha_de_nacimiento" value={solicitud.fecha_de_nacimiento} aria-label="Fecha_de_nacimiento" aria-describedby="fecha_de_nacimiento" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-5 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" htmlFor="sexo">Sexo</span>
													</div>
													<select className="custom-select" name="sexo" value={solicitud.sexo} id="sexo" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Hombre">Hombre</option>
														<option value="Mujer">Mujer</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-5 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="nacionalidad">Nacionalidad</label>
													</div>
													<select className="custom-select" name="nacionalidad" value={solicitud.nacionalidad} id="nacionalidad" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Mexicano">Mexicano</option>
														<option value="Extranjero">Extranjero</option>
													</select>
												</div>
											</div>
											{
												(solicitud.nacionalidad==="Mexicano")?
													<div className="col-12 col-sm-12 col-md-7 col-xl-6">
														<div className="input-group input-group-sm mb-3">
															<div className="input-group-prepend">
																<label className="input-group-text" htmlFor="entidaddenacimiento">Entidad de nacimiento</label>
															</div>
															<select className="custom-select" name="entidaddenacimiento" value={solicitud.entidaddenacimiento} id="entidaddenacimiento" required onChange={this.handleInputChange}>
																<option value="">Selecciona...</option>
																<option value="Aguascalientes">Aguascalientes</option>
																<option value="Baja California">Baja California</option>
																<option value="Baja California Sur">Baja California Sur</option>
																<option value="Campeche">Campeche</option>
																<option value="Chiapas">Chiapas</option>
																<option value="Chihuahua">Chihuahua</option>
																<option value="Coahuila">Coahuila</option>
																<option value="Colima">Colima</option>
																<option value="Ciudad de Mexico">Ciudad de Mexico</option>
																<option value="Durango">Durango</option>
																<option value="Guanajuato">Guanajuato</option>
																<option value="Guerrero">Guerrero</option>
																<option value="Hidalgo">Hidalgo</option>
																<option value="Jalisco">Jalisco</option>
																<option value="Estado de Mexico">Estado de Mexico</option>
																<option value="Michoacan">Michoacan</option>
																<option value="Morelos">Morelos</option>
																<option value="Nayarit">Nayarit</option>
																<option value="Nuevo Leon">Nuevo Leon</option>
																<option value="Oaxaca">Oaxaca</option>
																<option value="Puebla">Puebla</option>
																<option value="Queretaro">Queretaro</option>
																<option value="Quintana Roo">Quintana Roo</option>
																<option value="San Luis Potosi">San Luis Potosi</option>
																<option value="Sinaloa">Sinaloa</option>
																<option value="Sonora">Sonora</option>
																<option value="Tabasco">Tabasco</option>
																<option value="Tamaulipas">Tamaulipas</option>
																<option value="Tlaxcala">Tlaxcala</option>
																<option value="Veracruz">Veracruz</option>
																<option value="Yucatan">Yucatan</option>
																<option value="Zacatecas">Zacatecas</option>
															</select>
														</div>
													</div>
												:
													<div className="col-12 col-sm-12 col-md-7 col-xl-6">
														<div className="input-group input-group-sm mb-3">
															<div className="input-group-prepend">
																<span className="input-group-text" id="entidaddenacimiento">País de nacimiento</span>
															</div>
															<input type="text" className="form-control" name="entidaddenacimiento" value={solicitud.entidaddenacimiento || ''} aria-label="Entidaddenacimiento" aria-describedby="entidaddenacimiento" required onChange={this.handleInputChange}/>
														</div>
													</div>
											}
											<div className="col-12 col-sm-12 col-md-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="municipiodenacimiento">Municipio<span className="xs i">&nbsp;(de nacimiento)</span></span>
													</div>
													<input type="text" className="form-control" name="municipiodenacimiento" value={solicitud.municipiodenacimiento || ''} aria-label="Municipiodenacimiento" aria-describedby="municipiodenacimiento" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Calle)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Calle" name="calle" value={solicitud.calle  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Num.)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Número" name="numero" value={solicitud.numero  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(Col.)</span></span>
													</div>
													<input type="text" className="form-control" placeholder="Colonia" name="colonia" value={solicitud.colonia  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domicilio">Domicilio<span className="xs i">&nbsp;(C.P.)</span></span>
													</div>
													
													<input type="text" className="form-control" placeholder="C.P." name="cp" value={solicitud.cp  || ''} aria-label="Domicilio" aria-describedby="domicilio" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row justify-content-start">
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefono">Teléfono del alumno</span>
													</div>
													<input type="text" className="form-control" name="telefono" value={solicitud.telefono  || ''} aria-label="Telefono" aria-describedby="telefono" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="celular">Celular del alumno</span>
													</div>
													<input type="text" className="form-control" name="celular" value={solicitud.celular  || ''} aria-label="Celular" aria-describedby="celular" required onChange={this.handleInputChange}/>
												</div>
											</div>
{/*											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nss">Número de Seguro Social</span>
													</div>
													<input type="text" className="form-control" name="nss" value={solicitud.nss || ''} aria-label="nss" aria-describedby="nss" required onChange={this.handleInputChange}/>
												</div>
											</div>*/}
											<div className="col-12 col-sm-12 col-md-9 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="correo">Correo electrónico</span>
													</div>
													<input type="text" className="form-control" name="correo" value={solicitud.correo || ''} aria-label="Correo" aria-describedby="correo" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="correo">CURP</span>
													</div>
													<input type="text" className="form-control" name="curp" value={solicitud.curp || ''} aria-label="CURP" aria-describedby="curp" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DEL PADRE O TUTOR </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombrepadre1">Nombre</span>
													</div>
													<input type="text" className="form-control" name="nombrepadre1" value={solicitud.nombrepadre1 || ''} aria-label="Nombrepadre1" aria-describedby="nombrepadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="sexopadre1">Sexo</label>
													</div>
													<select className="custom-select" name="sexopadre1" value={solicitud.sexopadre1} id="sexopadre1" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Hombre">Hombre</option>
														<option value="Mujer">Mujer</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonopadre1">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonopadre1" value={solicitud.telefonopadre1 || ''} aria-label="Telefonopadre1" aria-describedby="telefonopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-lg-12 col-xl-9">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domiciliopadre1">Domicilio</span>
													</div>
													<input type="text" className="form-control" name="domiciliopadre1" value={solicitud.domiciliopadre1 || ''} aria-label="Domiciliopadre1" aria-describedby="domiciliopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="trabajopadre1">Lugar de trabajo <span className="xs i">&nbsp;(Nombre de la empresa)</span></span>
													</div>
													<input type="text" className="form-control" name="trabajopadre1" value={solicitud.trabajopadre1  || ''} aria-label="Trabajopadre1" aria-describedby="trabajopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonotrabajopadre1">Teléfono del trabajo</span>
													</div>
													<input type="text" className="form-control" name="telefonotrabajopadre1" value={solicitud.telefonotrabajopadre1 || ''} aria-label="Telefonotrabajopadre1" aria-describedby="telefonotrabajopadre1" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DE UN SEGUNDO PADRE, MADRE O TUTOR </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="nombrepadre2">Nombre</span>
													</div>
													<input type="text" className="form-control" name="nombrepadre2" value={solicitud.nombrepadre2 || ''} aria-label="Nombrepadre2" aria-describedby="nombrepadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<label className="input-group-text" htmlFor="sexopadre2">Sexo</label>
													</div>
													<select className="custom-select" name="sexopadre2" value={solicitud.sexopadre2} id="sexopadre2" required onChange={this.handleInputChange}>
														<option value="">Selecciona...</option>
														<option value="Hombre">Hombre</option>
														<option value="Mujer">Mujer</option>
													</select>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonopadre2">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonopadre2" value={solicitud.telefonopadre2 || ''} aria-label="Telefonopadre2" aria-describedby="telefonopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-lg-12 col-xl-9">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="domiciliopadre2">Domicilio</span>
													</div>
													<input type="text" className="form-control" name="domiciliopadre2" value={solicitud.domiciliopadre2 || ''} aria-label="Domiciliopadre2" aria-describedby="domiciliopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="trabajopadre2">Lugar de trabajo <span className="xs i">&nbsp;(Nombre de la empresa)</span></span>
													</div>
													<input type="text" className="form-control" name="trabajopadre2" value={solicitud.trabajopadre2  || ''} aria-label="Trabajopadre2" aria-describedby="trabajopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-12 col-md-12 col-xl-4">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonotrabajopadre2">Teléfono del trabajo</span>
													</div>
													<input type="text" className="form-control" name="telefonotrabajopadre2" value={solicitud.telefonotrabajopadre2 || ''} aria-label="Telefonotrabajopadre2" aria-describedby="telefonotrabajopadre2" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
										<div className="row pt-4 pb-4">
											<h4 className="col-12 cen gr md"> DATOS DE CONTACTO EN CASO DE EMERGENCIA </h4>
										</div>
										<div className="row justify-content-start pb-4">
											<div className="col-sm-12 col-md-9 col-lg-7 col-xl-6">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="contactoemergencia">Nombre</span>
													</div>
													<input type="text" className="form-control" name="contactoemergencia" value={solicitud.contactoemergencia || ''} aria-label="contactoemergencia" aria-describedby="contactoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="telefonoemergencia">Teléfono</span>
													</div>
													<input type="text" className="form-control" name="telefonoemergencia" value={solicitud.telefonoemergencia || ''} aria-label="telefonoemergencia" aria-describedby="telefonoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
											<div className="col-12 col-sm-7 col-md-6 col-xl-3">
												<div className="input-group input-group-sm mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text" id="parentescoemergencia">Parentesco</span>
													</div>
													<input type="text" className="form-control" name="parentescoemergencia" value={solicitud.parentescoemergencia || ''} aria-label="parentescoemergencia" aria-describedby="parentescoemergencia" required onChange={this.handleInputChange}/>
												</div>
											</div>
										</div>
									</div>
									{/* BOTON GUARDAR */}
									<div className="card-footer der">
										<button className="btn btn-success" type="submit">
											<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
										</button>
									</div>
								</div>
							</form>

						</div>
					</div>

				</div>

				{/* PIE DE PAGINA */}

				<PieDePagina2 />

			</div>

		);
	}

}

export default Solicitud;